<template>
  <div class="learn-about">
    <div class="text-img">
      <div class="inner-wrapper">
        <div class="text-side">
          <p>
            {{ cms.page.fgFriendsGideons }}
          </p>
        </div>

        <div class="img-side">
          <img src="../assets/images/home-share.jpg"/>
        </div>
      </div>
    </div>

    <div class="body-wrap-outer">
      <div class="text-wrap">
        <h3 class="subtitle title-offset">{{ cms.page.fgFriendsAreUnique }}</h3>
        <p>
          <strong>{{ cms.page.fgGideons }}</strong>
          {{ cms.page.fgGideonAreChristainBusiness }}
          <router-link :to="{ name: 'LearnAboutGideons'}">
            {{ cms.page.fgClickHere }}
          </router-link>
        </p>
        <p>
          <strong>{{ cms.page.fgFriends }}</strong>
          {{ cms.page.fgHaveAHeartForSeeingPeople }}
        </p>

        <div class="join-faq-buttons-container">
          <router-link :to="{ name: 'BecomeAFriend'}">
            <button class="btn btn-default">{{ cms.page.fgJoinToday }}</button>
          </router-link>

          <router-link :to="{ name: 'FAQs' }">
            <button class="button btn btn-default">
              {{ cms.page.fgFaqs }}
            </button>
          </router-link>
        </div>

        <div class="">
          <p>
            {{ cms.page.fgNote }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapStores} from "pinia";
import {useAuthStore} from "@/stores/authStore"
import {useCmsStore} from "@/stores/cmsStore";
import {useDesignStore} from "@/stores/designStore";

export default {
  name: "LearnAboutFriends",
  data() {
    return {
      cms: {
        loaded: false,
        options: {
          Common: true,
          Components: ["login_modal"],
          LanguageCode: "",
          PageName: "learn_about_friends",
          MinistryUpdates: false,
        },
        page: {},
      },
    };
  },
  computed: {
    ...mapStores(useAuthStore, useCmsStore, useDesignStore),
  },
  async created() {
    this.setTitlePageFromCms()
    await this.fetchCmsData();
  },
  methods: {
    async fetchCmsData() {
      try {
        await this.cmsStore
            .getPageAndOrCommonAndOrComponents(this.cms.options)
            .then((results) => {
              if (results) {
                this.cms.page = results;
              } else return false;

              this.setTitlePageFromCms(results)
              this.setContentLoaded(true);
              return true;
            });
      } catch (e) {
        console.error(e);
      }
    },
    setContentLoaded(loadedStatus) {
      this.cms.loaded = loadedStatus;
    },
    setTitlePageFromCms(page) {
      const pageTitle = page && page.fgLearnAboutFriends ? page.fgLearnAboutFriends : 'Learn about Friends'
      const pageSubtitle = page && page.fgWeKnowTheValueOfFriends ? page.fgWeKnowTheValueOfFriends : 'We know the value of friends of gideons'

      this.designStore.setTitlePage({
        title: pageTitle,
        sub_title: pageSubtitle,
        img_class: "hero-default-desktop",
        btn_history: false,
        width: 500,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.learn-about {
  position: relative;
  z-index: 1;

  .text-img {
    color: white;
    @media (min-width: 992px) {
      margin-top: -25px;
    }

    .inner-wrapper {
      max-width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      @include MQ(L) {
        flex-direction: row;
      }

      .text-side {
        width: 60%;
        background: $blue;
        padding: 110px 150px;
        min-height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (min-width: 992px) {
          opacity: 0;
          animation: fadeInLeft 1s ease forwards 1s;
        }
        @media (max-width: $xxl) {
          min-height: 286px;
        }
        @media screen and (max-width: $xl) {
          min-height: 263px;
        }
        @media screen and (max-width: $lg) {
          padding: 46px 18px;
          min-height: 210px;
          width: 100%;
        }
        @media screen and (max-width: $sm) {
          padding: 27px 18px;
          min-height: 265px;
        }

        p {
          margin-bottom: 0;
        }
      }

      .img-side {
        position: relative;
        width: 40%;
        z-index: 1;
        margin: auto;
        margin-left: -35px;

        @media screen and (max-width: $lg) {
          width: calc(100% - 30px);
          margin: -25px auto 0;
        }
        @media (min-width: 992px) {
          height: 320px;
          opacity: 0;
          animation: fadeInRight 1s ease forwards 1s;
        }

        img {
          object-fit: cover;
          object-position: center;
          height: 100%;
          width: 100%;

        }
      }
    }
  }
}

.text-wrap {
  width: calc(100% - 20px);
  max-width: 100%;
  margin: 0 auto;
  padding: 50px 25px;
  @include MQ(L) {
    padding: 80px 525px 50px 30px;
  }
  @include MQ(XL) {
    padding: 80px 620px 50px 30px;
  }

  .btn {
    margin-bottom: 40px;
    @media screen and (max-width: $md) {
      display: flex;
      margin: 0 auto 40px;
    }
  }

  .text-center {
    text-align: center;

    p {
      margin: 0;
    }
  }
}

.join-faq-buttons-container {
  display: flex;
  justify-content: space-evenly;
}

@media screen and (max-width: 321px) {
  .join-faq-buttons-container {
    flex-wrap: wrap;
  }
}
</style>